import { ActionTree, Module, MutationTree } from "vuex";
import { RouterState, RootState } from "../type";
import { GetterTree } from "vuex";
import { apiAuth, apiconfig } from "@/api/app";
import { filterRoute, getAsyncRoute } from "@/router";

const state: RouterState = {
  authRoutes: [],
  is_set_route:false
};

const getters: GetterTree<RouterState, RootState> = {
  authRoutes: (state) => state.authRoutes,
  isSetRoute: (state) => state.is_set_route,
};

const mutations: MutationTree<RouterState> = {
  asyncRouters(state, data) {
    state.authRoutes = data;
  },
};

const actions: ActionTree<RouterState, RootState> = {
  setAsyncRouter(context) {
    console.log('重新加载路由')
    context.state.is_set_route = true;
    let user = context.rootGetters.userInfo;

    const routes = filterRoute(getAsyncRoute(user.role));

    context.commit("asyncRouters", routes);
   
  },
  clearRoute(context){
    console.log('重置路由')
    context.state.is_set_route  =false;
  },
};

const routerStore: Module<RouterState, RootState> = {
  state,
  mutations,
  actions,
  getters,
};

export default routerStore;
