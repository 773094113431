// 开发环境域名

const VUE_APP_API_URL = process.env.VUE_APP_BASE_API || `${location.origin}`
console.log(process.env)
export default {
    // 版本
    version: '1.9.0',
    baseURL: VUE_APP_API_URL+'/platformapi',
    cdnURL: VUE_APP_API_URL,
    tencentMapKey: 'FWEBZ-WHSHV-IRFPO-UNMRL-5EUWV-BFBFW',
}
