import Main from "@/layout/main.vue";
import Blank from "@/layout/blank.vue";
const routes = [
  {
    path: "/channel_provider",
    name: "channel_provider",
    meta: { title: "渠道商" },
    redirect: "/channel_provider",
    component: Main,
    children: [
      {
        path: "/channel_provider",
        name: "channel_provider",
        meta: {
          title: "渠道商管理",
          parentPath: "/channel_provider",
          icon: "icon_ziti_store",
          permission: ["view"],
        },
        component: Blank,
        redirect: "/channel_provider",
        children: [
          {
            path: "/channel_provider_grade/lists",
            name: "channel_provider_grade_lists",
            meta: {
              title: "渠道商等级",
              parentPath: "/channel_provider",
              permission: ["view"],
            },
            component: () => import("@/views/channel_provider/grade/lists.vue"),
          },
          {
            path: "/channel_provider_grade/edit",
            name: "channel_provider_grade_edit",
            meta: {
              hidden: true,
              title: "渠道商等级编辑",
              parentPath: "/channel_provider",
              permission: ["view"],
            },
            component: () => import("@/views/channel_provider/grade/edit.vue"),
          },
          {
            path: "/channel_provider",
            name: "channel_provider_lists",
            meta: {
              title: "渠道商列表",
              parentPath: "/channel_provider",
              permission: ["view"],
            },
            component: () =>
              import("@/views/channel_provider/channel_provider/lists.vue"),
          },
          {
            path: "/channel_provider/supplier_list",
            name: "suppler_supplier_list",
            meta: {
              hidden: true,
              title: "已绑定供应商",
              parentPath: "/channel_provider",
              permission: ["view"],
            },
            component: () =>
              import("@/views/channel_provider/supplier_list/lists.vue"),
          },
          {
            path: "/channel_provider/lists_detail",
            name: "lists_detail",
            meta: {
              hidden: true,
              title: "渠道商信息",
              parentPath: "/channel_provider",
              prevPath: "/channel_provider",
              permission: ["view"],
            },
            component: () =>
              import(
                "@/views/channel_provider/channel_provider/lists_edit.vue"
              ),
          },

          {
            path: "/channel_provider/modify_account",
            name: "modify_account",
            meta: {
              hidden: true,
              title: "修改渠道商超级管理员账号",
              parentPath: "/channel_provider",
              prevPath: "/channel_provider",
              permission: ["view"],
            },
            component: () =>
              import(
                "@/views/channel_provider/channel_provider/modify_account.vue"
              ),
          },
          {
            path: "/channel_provider/package_record",
            name: "package_record",
            meta: {
              hidden: true,
              title: "渠道商套餐记录",
              parentPath: "/channel_provider",
              prevPath: "/channel_provider",
              permission: ["view"],
            },
            component: () =>
              import(
                "@/views/channel_provider/channel_provider/package_record.vue"
              ),
          },
          {
            path: "/channel_provider/wallet_log",
            name: "wallet_log",
            meta: {
              hidden: true,
              title: "渠道商充值记录",
              parentPath: "/channel_provider",
              prevPath: "/channel_provider",
              permission: ["view"],
            },
            component: () =>
              import(
                "@/views/channel_provider/channel_provider/wallet_log.vue"
              ),
          },
        ],
      },
      {
        path: "/channel_provider/set_meal",
        name: "set_meal",
        meta: {
          title: "套餐管理",
          parentPath: "/channel_provider",
          icon: "icon_order_guanli",
          permission: ["view"],
        },
        component: Blank,
        redirect: "/channel_provider/set_meal",
        children: [
          {
            path: "/channel_provider/set_meal",
            name: "set_meal",
            meta: {
              title: "套餐列表",
              parentPath: "/channel_provider",
              permission: ["view"],
            },
            component: () => import("@/views/shop/set_meal/lists.vue"),
          },
          {
            path: "/channel_provider/set_meal_edit",
            name: "set_meal_edit",
            meta: {
              hidden: true,
              title: "商城套餐",
              parentPath: "/channel_provider",
              prevPath: "/channel_provider/set_meal",
              permission: ["view"],
            },
            component: () => import("@/views/shop/set_meal/edit.vue"),
          },
        ],
      },
    ],
  },
];

export default routes;
