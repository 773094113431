import Main from "@/layout/main.vue";
import Blank from "@/layout/blank.vue";

const routes = [
  {
    path: "/setting",
    name: "setting",
    meta: { title: "设置" },
    redirect: "/setting/sms",
    component: Main,

    children: [
      // {
      //   path: "/setting/system/config",
      //   name: "setting_system_config",
      //   meta: {
      //     title: "系统设置",
      //     icon: "icon_set_weihu",
      //     parentPath: "/setting",
      //     permission: ["view"],
      //   },
      //   component: () => import("@/views/setting/payment/pay_config.vue"),
      // },
      // {
      //   path: "/setting/payment/config",
      //   name: "setting_pay_config",
      //   meta: {
      //     title: "支付配置",
      //     icon: "icon_set_pay",
      //     parentPath: "/setting",
      //     permission: ["view"],
      //   },
      //   component: () => import("@/views/setting/payment/pay_config.vue"),
      // },
      {
        path: "/setting/sms",
        name: "sms",
        meta: {
          icon: "icon_notice_mail",
          title: "通知设置",
          parentPath: "/setting",
        },
        redirect: "/setting/sms/buyers/setting",
        component: Blank,
        children: [
          // {
          //   path: "/setting/sms/buyers/buyers",
          //   name: "sms_buyers",
          //   meta: {
          //     title: "业务通知",
          //     parentPath: "/setting",

          //     permission: ["view"],
          //   },
          //   component: () => import("@/views/setting/sms/buyers/buyers.vue"),
          // },
          {
            path: "/setting/sms/buyers/setting",
            name: "setting",
            meta: {
              hidden: true,
              title: "设置",
              parentPath: "/setting",
            },
            component: () =>
              import("@/views/channel_provider/sms/buyers/setting.vue"),
          },
          {
            path: "/setting/sms/buyers/business",
            name: "business",
            meta: {
              title: "验证码",
              parentPath: "/setting",

              permission: ["view"],
            },
            component: () =>
              import("@/views/channel_provider/sms/buyers/business.vue"),
          },
          // {
          //   path: "/setting/sms/seller",
          //   name: "seller",
          //   meta: {
          //     title: "卖家通知",
          //     parentPath: "/setting",

          //     icon: "icon_notice_seller",
          //     permission: ["view"],
          //   },
          //   component: () => import("@/views/setting/sms/seller.vue"),
          // },
          {
            path: "/setting/sms/sms",
            name: "sms",
            meta: {
              title: "短信设置",
              parentPath: "/setting",

              icon: "icon_notice_mail",
              permission: ["view"],
            },
            component: () => import("@/views/channel_provider/sms/sms.vue"),
          },
          {
            path: "/setting/sms/sms_edit",
            name: "sms_edit",
            meta: {
              hidden: true,
              title: "短信设置详情",
              parentPath: "/setting",
              prevPath: "/setting/sms/sms",
            },
            component: () =>
              import("@/views/channel_provider/sms/sms_edit.vue"),
          },
        ],
      },
      {
        path: "/setting/payment/pay_method",
        name: "setting_payment",
        meta: {
          title: "支付设置",
          parentPath: "/setting",
          icon: "icon_set_pay",
        },
        component: Blank,
        redirect: "/setting/payment/pay_method",
        children: [
          {
            path: "/setting/payment/pay_method",
            name: "setting_pay_method",
            meta: {
              title: "支付方式",
              parentPath: "/setting",
              permission: ["view"],
            },
            component: () =>
              import("@/views/channel_provider/payment/pay_method.vue"),
          },
          {
            path: "/setting/payment/pay_config",
            name: "setting_pay_config",
            meta: {
              title: "支付配置",
              parentPath: "/setting",
              permission: ["view"],
            },
            component: () =>
              import("@/views/channel_provider/payment/pay_config.vue"),
          },
          {
            path: "/setting/payment/pay_method_edit",
            name: "setting_pay_method_edit",
            meta: {
              hidden: true,
              title: "支付方式设置",
              parentPath: "/setting",
              prevPath: "/setting/payment/pay_method",
            },
            component: () =>
              import("@/views/channel_provider/payment/pay_method_edit.vue"),
          },
          {
            path: "/setting/payment/pay_config_edit",
            name: "setting_pay_config_edit",
            meta: {
              hidden: true,
              title: "支付配置设置",
              parentPath: "/setting",
              prevPath: "/setting/payment/pay_config",
            },
            component: () =>
              import("@/views/channel_provider/payment/pay_config_edit.vue"),
          },
        ],
      },
    ],
  },
];

export default routes;
