import index from "./index";
import shop from "./shop";
import channel from "./channel";
import setting from "./setting";
import { RouteConfig } from "vue-router";

const asyncRoutes: Array<RouteConfig> = [
  ...index, //首页
  ...shop, //渠道商
  ...channel, //渠道商
  ...setting, //渠道商
];
export default asyncRoutes;
