import router, { setAsyncAuthRoute, setRouter } from "./router";
import store from "./store";
import NProgress from "nprogress";

const loginPath = "/account/login";
const defaultPath = "/index";
const whiteList = ["login"];



NProgress.configure({ showSpinner: false });
router.beforeEach(async (to, from, next) => {
  NProgress.start();
  // 设置文档title
  to.meta?.title && (document.title = to.meta.title);

  const token = store.getters.token;

  if (token) {
    if (!store.getters.isSetRoute) {
      // //同步调用获取菜单方法
      await store.dispatch("setAsyncRouter"); //发请求获取菜单，并将菜单设置到vuex中，
      const asyncRouters = store.getters["authRoutes"];
      //循环后动态添加路由，addRoutes方法好像不能用了
      setRouter(asyncRouters);

      // console.log("加载路由", asyncRouters, to.path);
      let path = to.path == "/error/404" ? (to.redirectedFrom?to.redirectedFrom:asyncRouters[0].redirect) : to.path;
      console.log(to,from,router)
      next({ path, replace: true });
    } else {
      if (to.path === loginPath) {
        next({ path: defaultPath });
      } else {
        next();
      }
    }
  } else {
    if (whiteList.includes(to.name as string)) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next({ path: loginPath, query: { redirect: to.fullPath } });
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});
