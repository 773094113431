import Main from "@/layout/main.vue";
import Blank from "@/layout/blank.vue";
const routes = [
  {
    path: "/channel",
    name: "channel",
    meta: { title: "渠道" },
    redirect: "/channel/mp_wechat",
    component: Main,
    children: [
      {
        path: "/channel/mp_wechat",
        name: "mp_wechat",
        meta: {
          title: "微信公众号",
          parentPath: "/channel",
          icon: "icon_qudao_weixin",
        },
        component: Blank,
        redirect: "/channel/mp_wechat/index",
        children: [
          {
            path: "/channel/mp_wechat/index",
            name: "mp_wechat_index",
            meta: {
              title: "渠道设置",
              parentPath: "/channel",
              permission: ["view"],
            },
            component: () =>
              import("@/views/channel_provider/channel/mp_wechat/index.vue"),
          },
          // {
          //   path: "/channel/mp_wechat/menu",
          //   name: "mp_wechat_menu",
          //   meta: {
          //     title: "菜单管理",
          //     parentPath: "/channel",
          //     permission: ["view"],
          //   },
          //   component: () =>
          //     import("@/views/channel_provider/channel/mp_wechat/menu.vue"),
          // },
          // {
          //   path: "/channel/mp_wechat/reply/follow_reply",
          //   name: "follow_reply",
          //   meta: {
          //     title: "关注回复",
          //     parentPath: "/channel",
          //     permission: ["view"],
          //   },
          //   component: () =>
          //     import(
          //       "@/views/channel_provider/channel/mp_wechat/reply/follow_reply.vue"
          //     ),
          // },
          // {
          //   path: "/channel/mp_wechat/reply/keyword_reply",
          //   name: "keyword_reply",
          //   meta: {
          //     title: "关键字回复",
          //     parentPath: "/channel",
          //     permission: ["view"],
          //   },
          //   component: () =>
          //     import(
          //       "@/views/channel_provider/channel/mp_wechat/reply/keyword_reply.vue"
          //     ),
          // },
          // {
          //   path: "/channel/mp_wechat/reply/default_reply",
          //   name: "default_reply",
          //   meta: {
          //     title: "默认回复",
          //     parentPath: "/channel",
          //     permission: ["view"],
          //   },
          //   component: () =>
          //     import(
          //       "@/views/channel_provider/channel/mp_wechat/reply/default_reply.vue"
          //     ),
          // },
          // {
          //   path: "/channel/mp_wechat/reply/reply_edit",
          //   name: "reply_edit",
          //   meta: {
          //     title: "默认编辑",
          //     parentPath: "/channel",
          //     hidden: true,
          //   },
          //   component: () =>
          //     import(
          //       "@/views/channel_provider/channel/mp_wechat/reply/reply_edit.vue"
          //     ),
          // },
        ],
      },
    ],
  },
];

export default routes;
