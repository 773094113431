
import { Component, Prop, Vue } from "vue-property-decorator";
import LsHeader from "@/components/layout/header.vue";
import Layout from "@/layout/index.vue";
import LsAside from "@/components/layout/aside.vue";
import { RouteConfig } from "vue-router";
@Component({
  components: {
    LsHeader,
    Layout,
    LsAside,
  },
})
export default class Aside extends Vue {
  get asideMenu() {
    const { meta } = this.$route;
    const asyncRoutes: Array<RouteConfig> = this.$store.getters.authRoutes;
    let item = meta?.moduleName
      ? asyncRoutes.find((item) => item.meta?.moduleName === meta?.moduleName)
      : asyncRoutes.find((item) => item.path === meta?.parentPath);

    return item?.children;
  }
  get hideAsideMenu() {
    return this.asideMenu?.every((item: any) => item.meta?.hidden);
  }

  created() {
    console.log("???");
  }
}
