import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Secondary from "@/layout/secondary.vue";
import adminRouter from "./modules/admin/router";
import channelProviderRouter from "./modules/channel_provider/router";
Vue.use(VueRouter);
const isComplexDataType = (obj: any) =>
  (typeof obj === "object" || typeof obj === "function") && obj !== null;

const constantRoutes: Array<RouteConfig> = [
  {
    path: "*",
    redirect: "/error/404",
  },
  {
    path: "/account",
    name: "account",
    component: Secondary,
    redirect: "/account/login",
    children: [
      {
        path: "/account/login",
        name: "login",
        meta: {
          title: "登录",
          parentPath: "/account",
        },
        component: () => import("@/views/account/login.vue"),
      },
    ],
  },
  {
    path: "/error",
    name: "error",
    component: Secondary,
    redirect: "/error/404",
    children: [
      {
        path: "/error/404",
        name: "error_404",
        meta: {
          title: "404",
          parentPath: "/error",
        },
        component: () => import("@/views/error/404.vue"),
      },
      {
        path: "/error/500",
        name: "error_500",
        meta: {
          title: "500",
          parentPath: "/error",
        },
        component: () => import("@/views/error/500.vue"),
      },
    ],
  },
];
function createRouter(routes = []) {
  return new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [...routes, ...constantRoutes],
  });
}
//@ts-ignore
const router = createRouter([]);

export function filterRoute(routes: any) {
  return routes
    .map((item: any) => {
      if (item.redirect) {
        const data = item.children.find(
          (item1: any) => item1.path == item.redirect
        );
        if (!data && item.children[0]) {
          item.redirect = item.children[0].path;
        }
      }
      return item;
    })
    .filter((item: any) => {
      if (item.children && item.children.length == 0) {
        return false;
      }
      return true;
    });
}
export function setAsyncAuthRoute(routes: any) {
  //处理一下路由
  setRouter(routes);
}
export function getAsyncRoute(role: string) {
  let routes: any = role == "admin" ? adminRouter : channelProviderRouter;
  console.log(role, routes);
  routes = filterRoute(routes);
  return routes;
}
export function setRouter(route = []) {
  const newRouter = createRouter(route);
  //@ts-ignore
  router.matcher = newRouter.matcher;
  // //@ts-ignore
  // console.log(router.matcher.getRoutes())
  // console.log(router)
}
export default router;

const deepClone = function (obj: any, hash = new WeakMap()) {
  if (obj.constructor === Date) return new Date(obj); // 日期对象直接返回一个新的日期对象

  if (obj.constructor === RegExp) return new RegExp(obj); //正则对象直接返回一个新的正则对象

  //如果循环引用了就用 weakMap 来解决

  if (hash.has(obj)) return hash.get(obj);

  let allDesc = Object.getOwnPropertyDescriptors(obj);

  //遍历传入参数所有键的特性

  let cloneObj = Object.create(Object.getPrototypeOf(obj), allDesc);

  //继承原型链

  hash.set(obj, cloneObj);

  for (let key of Reflect.ownKeys(obj)) {
    cloneObj[key] =
      isComplexDataType(obj[key]) && typeof obj[key] !== "function"
        ? deepClone(obj[key], hash)
        : obj[key];
  }

  return cloneObj;
};
