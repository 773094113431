import channel_provider from "./channel_provider";
import index from "./index";
import setting from "./setting";
import channel from "./channel";
import { RouteConfig } from "vue-router";
const asyncRoutes: Array<RouteConfig> = [
  ...index, //首页
  ...channel_provider, //渠道商
  // ...order,
  // ...channel,
  ...setting, //设置
];
export default asyncRoutes;
